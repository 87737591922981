require('popper.js')


import "../stylesheets/application"
import "../stylesheets/wcare"
import "../stylesheets/fonts"

$(function() {
   function textpw(inp) {
     if (inp.attr("type") == "password") {
       inp.attr("type", "text");
     } else {
       inp.attr("type", "password");
     }
   }

   $(".hide-password").hide();
   $(".show-password").click(function() {
     $(this).toggle();
     $(".hide-password").toggle();
     var input = $($(this).attr("toggle"));
     textpw(input)
   });
   $(".hide-password").click(function() {
     $(this).toggle();
     $(".show-password").toggle();
     var input = $($(this).attr("toggle"));
     textpw(input)
   });

   $(".hide-password2").hide();
   $(".show-password2").click(function() {
     $(this).toggle();
     $(".hide-password2").toggle();
     var input = $($(this).attr("toggle"));
     textpw(input)
   });
   $(".hide-password2").click(function() {
     $(this).toggle();
     $(".show-password2").toggle();
     var input = $($(this).attr("toggle"));
     textpw(input)
   });


  $("#ww_pw1,#ww_pw2").on('input', function() {
    let a = $("#ww_pw1").val()
    let b = $("#ww_pw2").val()

    if (a != b) {
      $("#ww_pw2").css("background-color", "lightcoral") 
    }
    else {
      $("#ww_pw2").css("background-color", "white") 
    }

    $("#wwb").prop("disabled", a != b || a.length < 8);
    if (a.length < 8) {
      $("#ww_len").html("<strong>Wachtwoord is niet lang genoeg.</strong>")
    }
    else {
      $("#ww_len").html("")
    }

  });

  $(".gobkleur").each(function() {
    $(this).css('background-color', $(this).data('kleur'));
    $(this).css('border-top-color', $(this).data('kleur'));
  });

  $(".menu").hide();

  $(".menu-toggle").click(function() {
    $(".menu").toggle();
  });

})
